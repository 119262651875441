.footer{
    &__content{
        padding: 2rem 2rem 2rem 4rem;
        margin-top: 15rem;
        // height: 50rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // display: grid;
        // grid-template-columns: 1fr 1fr;;
        // justify-content: space-around;
        flex: 1 0 auto;
        // gap: 5rem;
        
    }
    // background-color: $color--secondary;
    background-image: linear-gradient(to right, rgba($color--secondary,.5), rgba($color--secondary,.8));
    &__name{
        font-size: 4rem;
        font-family: $font-family-title;
        line-height: 5rem;
        text-align: justify;
    }
    &__ul{
        margin-top: 3rem;
        list-style: none;
        text-align: justify;
        padding-left: 0;
    }
    &__sections{
        // display: block;
        text-decoration: none;
        color: $color--quaternary;
        font-size: 2rem;
    }
    &__social-media{
        
    }
    &__get-touch{
        flex: 1;
        box-shadow: 1rem 1rem 2rem rgba($color-black,.5);
        border-radius: 5px;
        padding: 1rem;        
        // position: relative;
        min-width: 35rem;
        max-width: 50rem;
        // height: 45rem;
        display: grid;
        // grid-template-rows: .2fr 1fr;
        &--canvas{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }

        &--title{
            font-family: $font-family-title;
            font-size: 2.3rem;
            text-align: center;
            align-self: center;
            font-weight: 700;
        }
        &--form{
            display: flex;
            flex-direction: column;        
            align-items: center;          
            gap: 1rem;
        }
        &__label{
            margin-top: 1rem;
            font-size: 1.5rem;
        }
        &__input{
            width: 100%;
            // padding-left: 1rem;
            padding: 1rem;
            border-radius: 5px;
            border: 1px solid $color-grey-dark-2;
            font-size: 1.1rem;            
            &--name{
                height: 3rem;
                &:invalid{
                    // border: 1px solid red;
                }
            }
            &--email{
                height: 3rem;

                &:invalid{
                    // border: 1px solid red;
                }
            }
            &--message{
                padding: 1rem;
                height: 20rem;
                text-align: start;
                border: 1px solid $color-grey-dark-2;
                font-family: $font-family-name;
                &:invalid{
                    // border: 1px solid red;
                }
            }

            &:focus {
                border: 3px solid #555;
              }
        }
    }
}