@import 'abstracts/functions';
@import 'abstracts/mixins';
@import 'abstracts/variables';

@import 'base/animations';
@import 'base/base';
@import 'base/typography';
@import 'base/utilities';

@import 'components/card';
@import 'components/grid';
@import 'components/hexagon';
@import 'components/list';
@import 'components/graphs';
@import 'components/textBlock';
@import 'components/parallax';
@import 'components/button';
@import 'components/table';

@import 'layout/header';
@import 'layout/navigation';
@import 'layout/footer';

@import 'pages/home';
@import 'pages/portfolio';
.carousel-inner{
    overflow: visible;
    }
.carousel-caption {
    position: relative !important;
    left: auto;
    right: auto;
}
@import "~bootstrap/scss/bootstrap";
