
.section-wallpaper{
    &__hexagon{
        &:hover{
            fill: blue;
            // transform: rotateY(180deg);
            background-image: url("../../images/yosemite.jpeg");
            
        }        
    }
}

.section-about{
    box-shadow: 0rem .5rem 1rem rgba($color-black,0);

    // display: grid;
    // grid-template-columns: 50% 50% ;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    margin-top: 10rem;
    width: 100%;
    background-image: linear-gradient(to right, rgba($color--secondary,.5), rgba($color--secondary,.8));
    // padding-bottom: .5rem;

    &__right{
        flex: 1;
        min-width: 50rem;
        position: relative;
    }

    &__title{
        position: relative;
        width: 100%;
        &--text{
            font-family: $font-family-title;
            font-size: $text-size-section-title;
            position: absolute;
            text-align: right;
            // left: 4rem;
            left: -34rem;
            transform: translateX(100vw);
            top: -7rem;
            z-index: 1;
            width: 30rem;
        }        
    }    
    
    &__text{
        display: inline-block;
        margin-top: 5rem;
        font-size: $text-size-default;
        width: 90%;
        // text-align: center;        
        text-align: justify;        
        padding-bottom: 4rem;
        

        &--highlight{
            transition: all .2s;
            font-weight: 700;
            color: $color--tertiary;
            &:hover{
                display: inline-block;
                background-color: $color--quaternary;
                transform: skewY(2deg) skewX(15deg) scale(1.1);
                text-shadow: .5rem 1rem 2rem rgba($color-black, .2);
            }
        }
    }

    &__photo{
        // height: 100%;        
        width: 100%;        
        object-fit: cover;
        object-position: 50% 50%;
        transition: all .3s ease;
        &:hover{
            transform: scale(1.2);
        }
    }

    &__canvas{
        flex: 1;
        min-width: 50rem;
        min-height: 30rem;
        overflow: hidden;        
    }
}

.section-services{
    // box-0hadow: 1rem .5rem 1rem rgba($color2black,.5);

    // display:"grid",
    // gridTemplateColumns: "1fr 1fr 1fr 1fr",
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    flex-wrap: wrap;
    position: relative;
    // width: 100%;
    justify-content: center;
    // height: 50rem;
    // grid-template-columns: 1fr 1fr 1fr 1fr;
    // gap: 1rem;    

    margin-top: 10rem;
    // position: relative;
    &__image--container{
        position: relative;
    }
    &__image{
        position: absolute;   
        width: 70%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &__box{
        display: grid;
        grid-template-columns: .5fr 1fr;
        flex: 1;
        min-width: 30rem;
        max-width: 50rem;
        // height: 23rem;
        background-image: linear-gradient(to right, rgba($color--secondary,.5), rgba($color--secondary,.8));
        box-shadow: 0rem .5rem 1rem rgba($color-black,0);        
        &:hover{
            // font-weight: 700;
            color: $color--tertiary;
            text-shadow: .5rem 1rem 2rem rgba($color-black, .2);
        }      
    }

    &__title{
        position: relative;
        &--text{
            font-family: $font-family-title;
            font-size: $text-size-section-title;
            position: absolute;
            left: 4rem;
            text-align: left;            
            top: -8rem;
            // width: 30rem;
        }        
    }

    &__text{
        display: grid;
        // grid-template-rows: .4fr 1fr;
        grid-template-rows: 5rem 21rem;
    }

    // Title at the topo of the card
    &__subtitle{        
        font-family: $font-family-title;
        // font-size: 2.5rem;     
        font-size: $text-size-medium-1;
        font-weight: 550;
        padding-top: 1rem;
        // margin-top: 5rem;
        // margin-bottom: -5rem;
        text-align: center;
        align-self: center;
    }    

    &__description{
        // font-size: 2rem;
        // font-size: $text-size-default;
        font-size: $text-size-small-1;
        // padding: 1rem 1.5rem 1.5rem 1.5rem;
        padding: 1rem;
        // text-align: justify;
        align-self: center;
        font-size: $text-size-default;
    }    
    &__cover{
        // position: absolute;
        // left: -50%;
        // top: -50%;
        // transform: translate(50%,50%);
        position: relative;
        width: 100%;
        height: 100%;
        font-family: $font-family-title;
        &--text{
            color: white;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            font-size: 4rem;
            text-align: center;
            line-height: 5rem;
        }
    }

    &--DataAnalyst{
        // background-image: linear-gradient(to right bottom, rgba(197, 86, 86, 0.5), rgba(15, 93, 135, 0.5)), url("../../images/Data Analyst.jpg");
        background-image: linear-gradient(to left bottom, rgba(255,255,255,.6), rgba(55, 55, 55, 0.9)), url("../../images/Data Analyst.jpg");
        background-size: cover;
    }

    &--DataScientist{
        // background-image: linear-gradient(to right bottom, rgba(197, 86, 86, 0.5), rgba(15, 93, 135, 0.5)), url("../../images/Data Scientist.jpg");
        background-image: linear-gradient(to left bottom, rgba(255,255,255,.6), rgba(55, 55, 55, 0.9)), url("../../images/Data Scientist.jpg");
        background-size: cover;
    }

    &--FullStack{
        // background-image: linear-gradient(to right bottom, rgba(197, 86, 86, 0.5), rgba(15, 93, 135, 0.5)), url("../../images/Full Stack.jpg");
        background-image: linear-gradient(to left bottom, rgba(255,255,255,.6), rgba(55, 55, 55, 0.9)), url("../../images/Full Stack.jpg");
        background-size: cover;
    }

    &--Consultant{
        // background-image: linear-gradient(to right bottom, rgba(197, 86, 86, 0.5), rgba(15, 93, 135, 0.5)), url("../../images/Consultant.jpg");
        background-image: linear-gradient(to left bottom, rgba(255,255,255,.6), rgba(55, 55, 55, 0.9)), url("../../images/Consultant.jpg");
        background-size: cover; 
    }
}

.section-portfolio{
    box-shadow: 0rem .5rem 1rem rgba($color-black,0);
    margin-top: 10rem;
    width: 100%;
    // background-color: $color--secondary;
    background-image: linear-gradient(to right, rgba($color--secondary,.5), rgba($color--secondary,.8));
    background-size: cover;
    position: relative;
    // display: ;
    &__title{
        position: relative;
        &--text{
            font-family: $font-family-title;
            font-size: $text-size-section-title;
            left: 4rem;
            text-align: left;            
            // transform: translate(-50%,0);
            font-size: $text-size-section-title;
            position: absolute;
            top: -5rem;            
        }
    }
    &--project{
        width: 100%;
        min-height: 20rem;
        background-color: white;
    }

    &__container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
    &__children{
        flex: 1;
        min-width: 30rem;
        max-width: 60rem;
        overflow: hidden;
    }
    
    &__image{
        display: inline-block;
        min-width: 30rem;    
        max-width: 60rem;    
        width: 100%;
        height: 30rem;
        object-fit: cover;
        // object-fit: ;
        opacity: 1;        
        transition: all .3s ease;
        position: relative;
        overflow: hidden;
        // object-position: ;
        &:hover{
            transform: scale(1.2);
            z-index: 1;
        }        
    }

    &__hexagon{
        display: inline-block;
        @include hexagon(30rem);
        object-fit: cover;
        object-position: top;
        opacity: 1;
        
        transition: all .3s ease;
        position: relative;
        &:hover{
            transform: scale(1.2);
            z-index: 1;
        }

        &--decrease{
            opacity: 0;
        }

    }

}

.section-skills{
    padding-top: 3rem;
    padding-bottom: .01rem;
    margin-top: 10rem;
    width: 100%;
    // background-color: $color--secondary;
    background-image: linear-gradient(to right, rgba($color--secondary,.5), rgba($color--secondary,.8));
    box-shadow: 0rem .5rem 1rem rgba($color-black,0);

    &__title{
        position: relative;
        &--text{
            font-family: $font-family-title;
            font-size: $text-size-section-title;
            position: absolute;
            left: 4rem;
            text-align: left;            
            top: -8rem;
        }        
    }
    
    &__list-ul{
        &__header{
            font-family: $font-family-title;
            font-size: 2.5rem;
            font-weight: 500;
        }
        &__li{
            position: relative;
            display: grid;
            grid-template-columns: .5fr 1fr;
            justify-content: center;
            place-items: center;
        }
    }

    &__level{
        display: inline-block;
        width: 10rem;
        height: 1.5rem;

        justify-self: center;

        &--all{
            height: 1.3rem;
            border-radius: 3px;
            background-color: $color--tertiary;
            position: absolute;
        }

        &--color{
            // border: 1px solid $color-grey-light-1;
            box-shadow: 0 .5rem 1rem rgba($color-shadow-1, .15);
            width: 1rem;
            height: 1rem;
            display: inline-block;
            margin-right: .5rem;
            border-radius: 100%;
            background-color: $color--tertiary;
        }
        &--transparent{
            box-shadow: 0 .5rem 1rem rgba($color-shadow-1, .15);
            border: 1px solid $color-grey-light-1;
            width: 1rem;
            height: 1rem;
            display: inline-block;
            margin-right: .5rem;
            border-radius: 100%;
        }        
    }
    
    &__skill{
        display: inline-block;
        font-size: $text-size-default;
        justify-self: left;
    }

    &__bottom{
        width: 100%;
        height: 1rem;
    }

    &__columns{
        display: flex;
        // grid-template-columns: 1fr 1fr 1fr;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    &__content{
        flex: 1;
        min-width: 30rem;
        max-width: 50rem;
        padding-top: 1rem;
    }

}

.hexagon_tool_tip{
    position:absolute;
    top:0;
    // left:0;
    padding:5rem;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    line-height: 1.7;
    // color: $color-grey-light-1;
    color: $color--quaternary;
    font-size: 1.5rem;
    width: 50rem;
}