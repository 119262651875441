.table{
    // border: 1px solid;
    outline: 1px solid;
    &__header{
        &--1{
            background-color: $color--tertiary;
        }   
        &--2{

        }   
    }
    
}