.header__parallex{
    filter: saturate(.3);
}

.portfolio__parallax{
    overflow: visible !important;
    filter: saturate(.7);
}

.testParallax{
    // background-image: linear-gradient(
    //     to right bottom, 
    //     rgba($color--primary,.9), 
    //     rgba($color--primary, 1), 
    //     rgba($color--primary, .9)), url("../../images/background_geometric.png");
    background-image: linear-gradient(
        to right bottom, 
        rgba($color--primary,.9), 
        rgba($color--primary, 1), 
        rgba($color--primary, .9)), url("../../images/background_geometric.png");
    // height: 100rem;
    // display: block;
}