.btn{
    cursor: pointer;
    &__hamburger{
        // justify-self: end;
        // align-self: start;
        position: absolute;
        transform: translateX(26rem);
        left: 0;
        &__flex{
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
        &__grid{
            display: grid;

            grid-template-rows: 1fr 1fr 1fr;
                    
            &--bar1, &--bar2, &--bar3{
                text-align: center;
                // display: inline-block;
                width: 35px;
                height: 4px;
                background-color: #333;
                margin: 3px 0;
                transition: 0.4s;            
            }

            .change &--bar1 {
                -webkit-transform: rotate(-45deg) translate(-9px, 6px);
                transform: rotate(-45deg) translate(-9px, 6px);
            }
              
            .change &--bar2 {opacity: 0;}
              
            .change &--bar3 {
                -webkit-transform: rotate(45deg) translate(-7px, -6px);
                transform: rotate(45deg) translate(-7px, -6px);
            }

            &:hover{
                cursor: pointer;
            }
        }        
    }
    &__pressed {
        background-color: #6d9bf0;       
    }

}
