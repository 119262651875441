.card{

    min-height: 30rem;
    perspective: 100rem;
    -moz-perspective: 100rem;
    position: relative;    
    


    &__front{
        height: 100%;
        width: 100%;
        position: absolute;
        transition: all .8s ease;
        backface-visibility: hidden;
        perspective: 150rem;
        -moz-perspective: 150rem;
        border-radius: 5px;
    }

    &__back{        
        height: 100%;
        width: 100%;
        position: absolute;
        // background-image: linear-gradient(to right bottom, $color--secondary, $color--tertiary);
        background-color: $color--secondary;
        transition: all .8s ease;
        backface-visibility: hidden;
        perspective: 150rem;
        -moz-perspective: 150rem;
        border-radius: 5px;
        transform: rotateY(180deg);
        
    }
    
    &:hover &__front{
        transform: rotateY(-180deg);                
    }
    &:hover &__back{
        transform: rotateY(0deg);
    }
}