.portfolio{
    padding: 3rem;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    &__sidebar{
        display: grid;
        // grid-template-columns: 1fr .1fr .2fr;
        grid-template-rows: .1fr 1fr;
        width: 31rem;
        position: fixed;
        height: 100%;
        // background-color: red;
        // background-image: linear-gradient(to right, rgba($color--tertiary,.9), $color--secondary);        
        vertical-align: middle;
        top: 0;
        left: -25rem;
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 60px;
        font-size: 2rem;
        z-index: 1;
        
        &--open{
            // background: linear-gradient(to right, rgba($color--tertiary,.9), $color--secondary 80%, transparent);
            background: rgba($color--tertiary--light,.95);
        }
        
        &--close{

        }

        &--header{
            // position: relative;
            display: grid;
            grid-template-columns: 1fr .2fr;
            // height: 3rem;
        }
        
        &--items{
            display: grid;
            grid-template-columns: 1fr .2fr;
        }

        &--titles{
            &--item{
                height: 4rem;
                margin-bottom: 4rem;
                &:hover{
                    cursor: pointer;
                }
            }
        }
        &--timeline{
            // background-color: yellow;
            &--item{
                &--step{
                    height: 4rem;
                    width: 4rem;
                    background-color: $color--secondary;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    text-decoration: none;
                    color: $color--quaternary;     
                    
                    &:hover{
                        cursor: pointer;
                    }
                }

                &--bar{
                    height: 4rem;
                    // background-color: $color--secondary;
                    width: 1rem;
                    text-align: center;
                    display: inline-block;
                }

            }
        }
    }
    &__content{
        background-color: $color--secondary;
        // position: absolute;
        // left: 10rem;
        transition: 0.5s;     
        margin-left: 3rem;
        padding-left: 1rem;
    }
    &__content--empty{
        transition: 0.5s;     
        margin-left: 3rem;
        padding-left: 1rem;
    }
}

// const classLetters = {
//     'ARRIVED':'x1',
//     'WAITING':'x2',
//     'GET':'x3',
//     'FREE':'x4',
//     'LEAVING':'x5',
//     'MOVING':'x6',
//     'EXIT':'x7'
// }
// ARRIVED
.x1{
    fill: rgb(0,0,255);
}
// WAITING
.x2{
    fill: yellow;
}
// GET
.x3{
    fill: green;
}
// FREE
.x4{
    fill: blue;
}
// LEAVING
.x5{
    fill: blue;
}
// MOVING
.x6{
    fill: yellow;
}
// EXIT
.x7{
    fill: blue;
}