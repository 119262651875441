.textBlock{    
    font-size: $text-size-default;
    text-align: justify;
    border-radius: 5px;
    margin: 2px;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    &__title{
        // font-size: $text-size-section-title;
        font-size: 3.5rem;
        font-weight: 900;
    }
    &__subtitle{
        font-size: $text-size-big;
        font-weight: 700;
    }
    &__subsubtitle{
        font-size: $text-size-medium-big;
        font-weight: 600;
    }
    &__breakLine{
        margin-bottom: 4rem;
    }
    &__center{
        align-items: center;
        justify-content: center;
    }
}
