@mixin clearfix {
    &::after{
        content: '';
        display: table;
        clear: both;
    }
}

@mixin col-x-of-y($x,$y) {
    width: calc(#{$x} * (100% - (#{$y} - 1) * #{$gutter-horizontal})/#{$y}  + (#{$x} - 1) * #{$gutter-horizontal});
}

@mixin hexagon($edge) {
    width: $edge;
    height: $edge;
    -webkit-clip-path: polygon(0 20%, 50% 0, 100% 20%, 100% 80%, 50% 100%, 0 80%);
    clip-path: polygon(0 20%, 50% 0, 100% 20%, 100% 80%, 50% 100%, 0 80%);
}

@mixin level-number($number) {
    
}