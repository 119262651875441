.header{
    display: block;
    height: 50rem;
    width: 100%;
    // background-image: linear-gradient(to right bottom, rgba(255, 255, 255, 0.8), rgba(126, 126, 126, 0.8)), url("../images/globe.jpeg");
    // background-image: linear-gradient(to left, rgba($color--primary,0.3), rgba($color--primary, 0.3)), url("../../images/header_montains_low.jpeg");
    background-image: linear-gradient(to left, rgba($color--primary,0.3), rgba($color--primary, 0.3));
    filter: saturate(0.4);
    background-size: cover;
    background-position: 50% 30%;    
    position: relative;

    &__name{
        color:rgb(255,255,255);        
        // text-align: center;
        // -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
        // clip-path: polygon(0 0, 35% 0, 55% 100%, 0 100%);
        background-blend-mode: screen;
        // background-color: rgba(126, 126, 126, 0.8);
        // background-image: linear-gradient(to right bottom, rgba(197, 86, 86, 0.5), rgba(15, 93, 135, 0.5));
        // height: 100%;
        // padding-top: 20rem;
        // padding-left: 3rem;
        // font-family: Sarala, Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-family: $font-family-title;
        font-weight: 500;
        position: absolute;
        left: 50%;
        top: 50%;
        
        transform: translate(-50%,-50%);
        display: grid;
        grid-template-rows: 10rem 10rem 6rem;
        
        &--row{            
            font-size: 8rem;
            justify-self: center;
            align-self: center;
        }

        &--subname{
            font-size: $text-size-default;    
            justify-self: center;
            align-self: center;            
        }

        &--first{
            display: block;            
        }
        &--last{
            display: block;                        
            line-height: 5rem;
            // transform: translate(20%,-80%);
            // margin-left: 5rem;
        }
    }
}