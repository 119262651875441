.row{
    max-width: 200rem;
    margin: 0 auto;
    margin-bottom: $gutter-vertical;
    
    @include clearfix;
    
    [class^='col-']{
        float:left;
        &:not(:last-child){
            margin-right: $gutter-horizontal;
        }
    }

    .col-1-of-2{
        @include col-x-of-y(1,2);
    }
    .col-1-of-3{
        @include col-x-of-y(1,3);        
    }
    .col-1-of-4{
        @include col-x-of-y(1,4);        
    }
    .col-2-of-3{
        @include col-x-of-y(2,3);        
    }
    .col-2-of-4{
        @include col-x-of-y(2,4);
    }    
    .col-3-of-4{
        @include col-x-of-y(3,4);
    }
}
