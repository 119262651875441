*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html{
    font-size: 62.5%;
    // padding: 1rem;
    // width: 100%;
    overflow-x: hidden;
}

body{
    box-sizing: border-box;
    background-color: #292525;
    background-color: $color--primary;
    // background-image: linear-gradient(to right, rgba($color--primary,.1), rgba($color--primary, 1)), url("../../images/background_geometric.png");
    // background-image: linear-gradient(
    //     to right bottom, 
    //     rgba($color--primary,.9), 
    //     rgba($color--primary, 1), 
    //     rgba($color--primary, .9)), url("../../images/background_geometric.png");
}