.pressing {
    transition: all .2s;
    box-shadow: 0 .2rem 2rem $color-shadow-1;
    &:hover{
        transform: translateY(-.5rem);   
        box-shadow: 0 .6rem 2rem $color-shadow-1;
    }
    &:active{
        transform: translateY(-.2rem);
        box-shadow: 0 .3rem 2rem $color-shadow-1;
    }
}