.columnsHistogram{
    fill:$color--tertiary;       
}

.y-axis--label{
    font-size: $text-size-default;
}

.react-parallax-background-children{
    width: 100%;    
}

.graphicButton{
    // display: grid;
    padding-top: 4rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &__Item{
        cursor: pointer;
        
        background-color: $color--tertiary;
        // display:'block';
        flex-shrink: 0;
        width:10rem;
        font-size: $text-size-default;
    }
}

.graphicButtonContainer{
    display: flex;
    flex-direction: row;
    height: 40rem;
    width: 80%;
    margin-bottom: 1.5rem;
}

.graphicBarSize{
    // flex-flow: column wrap;
    // flex-grow: 1;
    // width: 100%;
}