//COLOR
// $color--primary: #8889a1;
// $color--primary: rgb(106,125,159);
// $color--primary: rgb(82,86,127);
// $color--primary: rgb(12, 23, 49);
// $color--secondary: rgb(147,130,105);
// $color--tertiary: #a64515;
// $color--quaternary: rgb(59,175,202);
// $color--primary: rgb(34, 42, 53);
// $color--secondary: rgb(80,97,79);
// $color--secondary: rgb(34, 42, 53);
// $color--primary: rgb(49, 76, 107);
// $color--secondary: rgb(47, 59, 76);
// $color--tertiary: rgb(214,164,72);
// $color--quaternary: rgb(117,86,94);
// $color--secondary: #ABA6A1;

$color--primary: #F7F2EF;
$color--secondary: #E7E3E2;
// $color--tertiary: #E6CE1D;
$color--tertiary: rgb(106, 145, 186);
$color--tertiary--light: rgb(161, 207, 255);
$color--quaternary: #2F2E2C;
// $color--quaternary: #E6CE1D;

$color--polygon-effect: rgb(128, 173, 222);


$color--low: #859bb1;
$color--medium: #4f76a0;
$color--high: #092a4d;


$color--2: #a1a2b9;
$color--3: #b9bad0;
$color--4: #d2d3e8;
$color--5: #eaebff;
$color-shadow-1: rgba(0, 0, 0, 0.4);
$color-shadow-2: rgba(0, 0, 0, 0.8);
$color-grey-light-1:#f7f7f7;
$color-grey-light-2:#aaaaaa;
// $color--secondary: #777;
// $color--secondary: #4B4944;
// $color--secondary: rgb(136,139,178);
// $color--secondary: rgb(178,174,136);
// $color--secondary: rgb(167,151,113);
$color-grey-dark-2: #333;
$color-white:white;
$color-black:#000;

//TEXT
$text-size-default: 1.6rem;
$text-size-small: 1rem;
$text-size-small-1: 1.4rem;
$text-size-medium-1: 1.8rem;
$text-size-big: 3rem;
$text-size-medium-big: 2.5rem;
$text-size-section-title: 5rem;

$gutter-horizontal: 5rem;
$gutter-vertical: 2rem;
$font-family-name: sans-serif, 'Roboto', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue';

$font-family-title:'futura', sans-serif, 'Roboto', 'Ubuntu', 'Cantarell', 'Open Sans', 'Helvetica Neue';
